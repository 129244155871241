section.brands {
  overflow: hidden;
}
.brand-imgs img {
  width: 100%;
  max-width: 190px;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
a.brand-imgs:hover {
  filter: grayscale(0);
  transition: all 0.5s;
}
.brand-imgs {
  filter: grayscale(100%);
  transition: all 0.5s;
}
.brands{
  padding-bottom: 0px;
    padding-top: 0px;
}
/* 992 */
@media screen and (max-width:1199px) {
  .slick-initialized .slick-slide {
    display: block;
}
}
/* 768 */
@media screen and (max-width:991px) {

}
/* 320 */
@media screen and (max-width:330px) {
  button.btn-black.get-in-touch {
    margin: 90px 0 0 0 !important;
    padding: 11px 20px;
}
section.brands {
  overflow: hidden;
}
}
