a.btn-black.get-in-touch {
  padding: 12px 20px;
  font-size: 16px;
}

.design-approach-card {
  padding: 30px;
  height: 100%;
}

.design-approach-card h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  color: #1f271b;
  line-height: 21px;
}

.work-card-title.design-approach-title {
  line-height: 31px;
  margin: 201px 0 160px 0;
}

.digital-experience {
  width: 100%;
  max-width: 825px;
  margin: 70px 0 0 auto;
}
/* 992 */
@media screen and (max-width: 1199px) {
  .design-approach-card {
    padding: 23px;
  }
  .digital-experience {
    max-width: 100%;
}
}
/* 768 */
@media screen and (max-width:991px) {
    .digital-experience {
        margin: 30px 0 0 0;
    }
}
/* 576 */
@media screen and (max-width:767px) {
    h4.work-card-title.design-approach-title {
        font-size: 20px;
        line-height: 27px;
        margin: 150px 0 108px 0;
    }
    a.btn-black.get-in-touch {
        padding: 10px 16px;
        font-size: 15px;
        font-weight: 400;
    }
    .design-approach-card {
        padding: 20px 9px;
    }
}
/* 480 */
@media screen and (max-width:575px) {

    h4.work-card-title {
        font-size: 20px;
        margin: 0 0 10px 0;
    }
    .design-approach-card {
        padding: 20px;
    }
    h4.work-card-title.design-approach-title {
        margin: 100px 0 80px 0;
    }
    .design-approach-card.our-team-card a.btn-black.get-in-touch {
        margin: 90px 0 0 0;
    }
    .design-approach-card {
        padding: 20px 20px 30px 20px;
    }
}
/* 320 */
@media screen and (max-width:330px) {
    a.btn-black.get-in-touch {
        font-size: 14px;
    }
 
    .design-approach-card h6 {
        font-size: 14px;
    }
}