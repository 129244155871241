a.footerlogo img {
    width: 100%;
    max-width: 220px;
    object-fit: contain;
    object-position: center;
}

h4.footer-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 25px 0;
}

ul.footermenu li i {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 1px solid #1f271b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #1f271b;
    background: #fff;
    margin: 0 15px 0 0;
}

ul.footermenu li {
    margin: 0 0 25px 0;
}

ul.footermenu li h6 {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
    margin: 0 0 5px 0;
    color: #1f271b;
}

p.footer-text, a.footer-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #1f271b;
}

.map iframe {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.map {
    width: 100%;
    max-width: 350px;
    height: 350px;
}

p.copyright-text {
    font-family: 'Montserrat';
    font-style: normal;
    text-align: center;
    margin: 30px auto 0;
    font-size: 14px;
    font-weight: 500;
    border-top: 1px solid #0000000d;
    padding: 15px 0 20px;
}
footer {
    padding: 60px 0px 0px;
}
.footer-text{
    text-decoration: none;
}
/* 768 */
@media screen and (max-width:991px) {
    h4.footer-title {
        font-size: 21px;
        margin: 0 0 20px 0;
    }
    ul.footermenu li i {
        height: 35px;
        width: 35px;
        font-size: 15px;
        margin: 0 10px 0 0;
    }
    ul.footermenu li h6 {
        font-size: 15px;
        margin: 0 0 5px 0;
    }

    p.footer-text, a.footer-text {
        font-size: 14px;
    }
    .map {
        max-width: 232px;
        height: 230px;
    }
    footer {
        padding: 0;
    }
    ul.footermenu li {
        flex-direction: column;
        align-items: start !important;
    }

    ul.footermenu li i {
        margin: 0 0 10px 0;
    }
}
/* 576 */
@media screen and (max-width:767px) {
    h4.footer-title {
        font-size: 19px;
    }

    .footerlogo img {
        margin-bottom: 40px;
    }
}
/* 375 */
@media screen and (max-width:479px) {
 
    p.copyright-text {
        font-size: 13px;
    }
}
/* 320 */
@media screen and (max-width:330px) {

    h4.footer-title {
        font-size: 18px;
    }
}