/* Our Team */

.work-card-title.design-approach-title.our-team-title {
  font-size: 24px;
  margin: 0 0 10px 0;
}

.design-approach-card.our-team-card a.btn-black.get-in-touch {
  margin: 149px 0 0 0;
}

.our-team-card i img {
  width: 100%;
  height: 320px;
  object-fit: contain;
  object-position: center;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

h6.developers {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.design-approach-card.our-team-card h4 {
  font-weight: 600;
}
.modal-content {
  border: none !important;
}
a {
  text-decoration: none;
}
button.btn-black.get-in-touch {
  margin: 149px 0 0 0;
  padding: 11px 20px;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
}
.imagePreview {
    width: 38px;
    height: 38px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 0 0 25px;
}
.file-select input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}
/* 992 */
@media screen and (max-width: 1199px) {
  .design-approach-card {
    padding: 23px;
  }
  .design-approach-card.our-team-card a.btn-black.get-in-touch {
    margin: 170px 0 0 0;
  }
  .btn-black {
    font-size: 16px;
    padding: 13px 27px;
  }
  section.our-team {
    padding: 60px 0 0 0;
  }
}
/* 768 */
@media screen and (max-width: 991px) {
    .design-approach-card.our-team-card a.btn-black.get-in-touch {
        margin: 188px 0 0 0;
    }
}
/* 576 */
@media screen and (max-width:767px) {
    .design-approach-card.our-team-card a.btn-black.get-in-touch {
        margin: 181px 0 0 0;
    }
    h6.developers {
        font-size: 17px;
    }
    .design-approach-card {
        padding: 20px 9px;
    }
}
/* 480 */
@media screen and (max-width:575px) {

    .design-approach-card {
        padding: 20px;
    }
}
/* 320 */
@media screen and (max-width:330px) {

    section.our-team {
        padding: 40px 0 0 0;
    }
    h4.work-card-title.design-approach-title.our-team-title {
        font-size: 21px;
    }
}