/*********************  Default-CSS  *********************/

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-moz-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-ms-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-o-file-upload-button {
  cursor: pointer;
}

input[type="file"],
a[href],
input[type='submit'],
input[type='button'],
input[type='image'],
label[for],
select,
button,
.pointer {
  cursor: pointer;
}

::-moz-focus-inner {
  border: 0px solid transparent;
}

::-webkit-focus-inner {
  border: 0px solid transparent;
}

*::-moz-selection {
  color: #fff;
  background: #000;
}

*::-webkit-selection {
  color: #fff;
  background: #000;
}

*::-webkit-input-placeholder {
  color: #333333;
  opacity: 1;
}

*:-moz-placeholder {
  color: #333333;
  opacity: 1;
}

*::-moz-placeholder {
  color: #333333;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #333333;
  opacity: 1;
}

html body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  line-height: 1.3;
}

a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

a,
span,
div a:hover,
div a:active,
button {
  text-decoration: none;
}

*::after,
*::before,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.no-list li,
.no-list ul,
.no-list ol,
footer li,
footer ul,
footer ol,
header li,
header ul,
header ol {
  list-style: inside none none;
}

.no-list ul,
.no-list ol,
footer ul,
footer ol,
header ul,
header ol {
  margin: 0;
  padding: 0;
}

a {
  outline: none;
  color: #555;
}

a:hover {
  color: #000;
}

body .clearfix,
body .clear {
  clear: both;
  line-height: 100%;
}

body .clearfix {
  height: auto;
}

* {
  outline: none !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul:after,
li:after,
.clr:after,
.clearfix:after,
.container:after,
.grve-container:after {
  clear: both;
  display: block;
  content: "";
}

div input,
div select,
div textarea,
div button {
  font-family: 'Lato', sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: 'Lato', sans-serif;
  line-height: 120%;
  color: #333;
  font-weight: bold;
  margin: 0 0 15px;
}

body h1:last-child,
body h2:last-child,
body h3:last-child,
body h4:last-child,
body h5:last-child,
body h6:last-child {
  margin-bottom: 0;
}

div select {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div select option {
  font-size: 13px;
  color: #333;
  padding: 2px 5px;
}

img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

body p {
  color: #333;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  line-height: 140%;
  margin: 0 0 15px;
  padding: 0;
}

body p:empty {
  margin: 0;
  line-height: 0;
}

body p:last-child {
  margin-bottom: 0;
}

p strong {
  font-weight: bold;
}

.a-left {
  text-align: left;
}

.a-right {
  text-align: right;
}

.a-center {
  text-align: center;
}

label em {
  color: #ff0000;
  display: inline-block;
  font-style: normal;
  vertical-align: top;
  margin-left: 5px;
}

.hidden {
  display: none !important;
}

body .container {
  width: 100%;
  max-width: 1270px;
}


/*********************  scroll Hide  *********************/

html.show-menu {
  overflow: hidden;
}

html.show-menu body {
  overflow: hidden;
  height: 100%;
}


/*********************  Default-CSS close  *********************/


/*********************  Gapping  *********************/

section {
  padding-bottom: 60px;
  padding-top: 60px;
}


/*********************  Gapping close  *********************/


/*********************  Default-CSS  *********************/


/*********************  Common-Css  *******************/

.clr:after,
ul:after,
.clearfix:after,
li:after,
.grve-container:after {
  clear: both;
  display: block;
  content: "";
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: unset;
}


/*********************  Common-Css Close  *******************/