section#Contact-Us {
  margin: 100px 0 0;
}

ul.im-interested li input[type="checkbox"] + label {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  border: 1px solid black;
  color: black;
  background-color: #f3f7fd;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}
.contact-info h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 67px;
  font-weight: 600;
  color: #000;
  margin: 0 0 90px 0;
}

.group-input {
  margin: 0 0 50px 0;
}

label.form-label.from-label-style {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  color: #000000b3;
}

.group-input textarea {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  resize: none;
  height: 37px;
  font-family: "Montserrat";
  font-size: 15px;
  background-color: #f3f7fd;
}

.group-input textarea:focus {
  background-color: #f3f7fd;
}

.group-input textarea::placeholder {
  font-family: "Montserrat";
  font-size: 14px;
}

.group-input input,
.group-input textarea {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  font-family: "Montserrat";
  font-size: 15px;
  background-color: #f3f7fd;
  font-weight: 500;
}

.group-input input:focus {
  background-color: #f3f7fd;
}

.group-input.group-input2 input,
.group-input.group-input2 textarea {
  background: #fff;
}

.group-input input::placeholder,
.group-input textarea::placeholder {
  font-family: "Montserrat";
  font-size: 14px;
}

.usd-values {
  margin: 15px 0 0 0;
}
label.usd-value-label {
  margin-right: 15px;
  position: relative;
}
.usd-values input[type="radio"] {
  width: 100px;
  height: 40px;
  border-radius: 0;
  border: 1px solid #000;
  background-color: #f3f7fd;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
.usd-values label span:first-of-type {
  position: absolute;
  left: 21px;
  font-size: 16px;
  color: #000;
}
.usd-values label span {
  position: absolute;
  top: 10px;
  cursor: pointer;
}
ul.im-interested {
  padding: 0;
  margin: 15px 0 0 0;
  clear: both;
}
ul.im-interested li {
  list-style-type: none;
  list-style-position: outside;
  padding: 0 10px;
  float: left;
}
.file-select {
  position: relative;
  overflow: hidden;
}
ul.im-interested li input[type="checkbox"]:not(:checked),
ul.im-interested li input[type="checkbox"]:checked {
  position: absolute;
  left: -9999%;
}
ul.im-interested li input[type="checkbox"] + label {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  border: 1px solid black;
  color: black;
  background-color: #f3f7fd;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}
.clr:after,
ul:after,
.clearfix:after,
li:after,
.grve-container:after {
  clear: both;
  display: block;
  content: "";
}
.file-select-name {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 0 18px;
}
button.btn.add-attachment-btn p {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 0 5px;
  color: #000;
}
button.btn.add-attachment-btn {
  padding-left: 0;
}
button.btn.add-attachment-btn i {
  font-size: 17px;
  color: #000;
  vertical-align: 0;
}
.send-request:hover {
  color: #fff;
  background: #000;
  transition: all 0.5s;
}

.send-request {
  display: inline-block;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  border: 1px solid #000;
  padding: 10px 20px;
  transition: all 0.5s;
  margin: 30px 0 45px;
}

button.send-request {
  background-color: #f3f7fd;
}

.send-request.submit2 {
  margin: 30px 0 0 0;
}

p.policy-text {
  max-width: 360px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

p.policy-text a {
  color: #555555bf;
  text-decoration: underline;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: unset;
  border-bottom: 1px solid #000 !important;
  border-radius: 0;
  border: none !important;
}

.input-group {
  border-bottom: 1px solid #000;
}

.group-input.group-input1 input {
  border: none;
}

.group-input2 input {
  background: #fff;
}

.group-input2 textarea {
  background: #fff;
}

.modal-header {
  border-bottom: none;
}

button.file-add {
  display: inline-block;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  border: 1px solid #000;
  padding: 3px 6px;
  border-radius: 0;
  transition: all 0.5s;
  background: transparent;
  margin: 0 0 5px 0;
}

.upload-card {
  border-bottom: 1px solid #0000000f;
  padding: 10px 0;
  border-top: 1px solid #0000000f;
}

button.btn.file-remove {
  color: red;
  display: inline-block;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid red;
  padding: 3px 6px;
  transition: all 0.5s;
  background: transparent;
  border-radius: 0;
  margin: 5px 0 0 0;
}
ul.im-interested li input[type="checkbox"]:checked + label {
  border: 1px solid white;
  color: white;
  background-color: black;
}
/* .usd-values .usd-value-label {
    display: inline-block;
    width: 200px;
    padding: 10px;
    border: solid 2px #ccc;
    transition: all 0.3s;
  } */
/* .usd-values input[type="radio"]{
    display: none;
} */
.usd-values input[type="radio"]:checked {
  background-color: #000;
}
.file-select input[type="file"] {
  display: inline-block;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  border: 1px solid #000;
  padding: 3px 6px;
  border-radius: 0;
  transition: all 0.5s;
  background: transparent;
  margin: 0 0 5px 0;
}
.usd-values input[type="radio"]:checked+span {
  color: white;
}
/* label.usd-value-label:checked span {
  color: white !important;
} */
/* 1200 */
@media screen and (max-width: 1429px) {
  .contact-info h2 {
    font-size: 60px;
    margin: 0 0 60px 0;
  }
  ul.im-interested li {
    margin: 0 0 20px 0;
  }
  li.web-development {
    padding-left: 0 !important;
  }
  .contact-info h2 {
    font-size: 50px;
    margin: 0 0 50px 0;
  }
}
/* 768 */
@media screen and (max-width: 991px) {
  section#Contact-Us {
    margin: 100px 0 50px;
  }
}
/* 576 */
@media screen and (max-width: 767px) {
  label.form-label.from-label-style {
    font-size: 15px;
  }
  li.web-development2 {
    padding-left: 0 !important;
  }

  .contact-info h2 {
    font-size: 45px;
    margin: 0 0 40px 0;
  }
  section#Contact-Us {
    margin: 100px 0 40px;
  }
}
/* 480 */
@media screen and (max-width: 575px) {
  .contact-info h2 {
    font-size: 37px;
    margin: 0 0 30px 0;
  }
  li.web-development3 {
    padding-left: 0 !important;
  }
}
/* 320 */
@media screen and (max-width: 330px) {
  li.web-development4 {
    padding-left: 0 !important;
  }
  .contact-info h2 {
    font-size: 30px;
    margin: 0 0 25px 0;
  }
}
