/*********************  Header  *******************/
.collapse:not(.show) {
  /* display: none; */
  display: block;
}
.navbar {
  padding: 25px 0px;
  transition: all 0.5s;
  background-color: white;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: all 0.5s;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 5px 50px #0000000d;
}
.navbar-brand {
  width: 100%;
  max-width: 200px;
  margin: 0;
  padding: 0;
  display: flex;
  /* justify-content:space-between; */
}
.navbar-brand img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
#navbarNav {
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 17px;
  font-weight: 500;
  border: 1px solid #1f271b;
  color: #1f271b;
  padding: 15px 30px;
  border-radius: 50px;
  transition: all 0.5s;
  background-color: white;
}
#navbarNav:hover {
  background: #1f271b;
  color: #fff;
  transition: all 0.5s;
}
.navbar.active{
  /* background-color: black; */
  padding: 15px 0;
}

/* header mobile */

button.navbar-toggler.collapsed {
  margin-left: auto;
  border: none !important;
}
.navbar-toggler:focus {
  border: none;
  /* text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); */
  box-shadow: none !important;
}

span.navbar-toggler-icon::after {
  content: '';
  height: 1px;
  width: 30px;
  background: #000;
  bottom: -8px;
  left: 0;
  position: absolute;
}
span.navbar-toggler-icon:before {
  content: '';
  height: 1px;
  width: 30px;
  background: #000;
  top: -8px;
  left: 0;
  position: absolute;
}
span.navbar-toggler-icon {
  background-image: none;
  height: 1px;
  width: 30px;
  background: #000;
  position: relative;
}
/* 375 */
@media screen and (max-width:479px) {
 
  #navbarNav {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #1f271b;
    color: #1f271b;
    padding: 9px 18px;
    border-radius: 50px;
    transition: all 0.5s;
    background-color: white;
}
}

/* 320 */
@media screen and (max-width:330px) {
  .navbar-brand {
    width: 100%;
    max-width: 179px;
    margin: 0;
    padding: 0;
    display: flex;
    /* justify-content: space-between; */
}
  #navbarNav {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #1f271b;
    color: #1f271b;
    padding: 10px 14px;
    border-radius: 50px;
    transition: all 0.5s;
    background-color: white;
  }
}
