.contact-container {
    padding: 60px 100px 80px;
}

.expert-team {
    max-width: 780px;
    margin-left: auto;
}

.expert-team h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 60px;
    font-weight: 400;
    line-height: 82px;
    color: #1f271b;
    margin: 0;
}

.expert-team p {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 19px;
    font-weight: 500;
    color: #1f271b;
    max-width: 770px;
    margin: 40px 0 60px;
}

.btn-black{
    text-decoration: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 17px;
    font-weight: 500;
    padding: 17px 30px;
    background: #1f271b;
    color: #ffffff;
    display: inline-block;
    border-radius: 50px;
    border: 1px solid #1f271b;
}
.btn-black:hover{
    color: #1f271b;
    background-color: transparent;
    border: 1px solid #1f271b;
    transition: all 0.5s;
}
/* 1200 */
@media screen and (max-width:1429px) {
    .expert-team h2 {
        font-size: 57px;
        line-height: 69px;
    }
}
/* 768 */
@media screen and (max-width:991px) {
    section.design-approach {
        padding: 0;
    }
    .contact-container {
        padding: 60px;
    }
    .expert-team h2 {
        font-size: 40px;
        line-height: 50px;
    }
    
    .expert-team p {
        font-size: 17px;
        margin: 30px 0 40px;
    }
}
/* 576 */
@media screen and (max-width:767px) {
    a.btn-black {
        font-size: 15px;
        padding: 12px 25px;
    }
    .contact-container {
        padding: 40px;
    }
    .expert-team h2 {
        font-size: 34px;
        line-height: 42px;
    }
    .expert-team p {
        font-size: 15px;
        margin: 30px 0 40px;
    }
}
/* 480 */
@media screen and (max-width:575px) {

    .expert-team h2 {
        font-size: 29px;
        line-height: 38px;
    }
    .contact-container {
        padding: 30px;
    }
    section.contact {
        padding: 40px 0 60px;
    }

}
/* 375 */
@media screen and (max-width:479px) {
 
    section.contact {
        padding: 20px 0 60px;
    }
}
/* 320 */
@media screen and (max-width:330px) {

    .expert-team h2 {
        font-size: 22px;
        line-height: 29px;
    }
    a.btn-black {
        font-size: 14px;
        padding: 10px 20px;
    }
}