section.work-cards {
    padding: 100px 0;
}

.work-card {
    padding: 30px;
    background: #f8f8f7;
    height: 100%;
}

.green {
    background: #def0eb;
}

.orange {
    background: #f2e8df;
}

.yellow {
    background: #fff5ce;
}

.blue {
    background: #f3f7fd;
}

.purple {
    background: #ecdeff;
}

.pink {
    background: #fdf3f3;
}

.work-card-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 10px 0;
}

p.info-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
}
@media screen and (max-width:1199px) {
    .work-card {
        margin: 0 0 23px 0;
    }
}
/* 992 */
@media screen and (max-width:1199px) {
    section.work-cards {
        padding: 100px 0 0px;
    }
}
/* 576 */
@media screen and (max-width:767px) {
    .work-card {
        padding: 20px;
    }
    p.info-text {
        line-height: 25px;
    }
}
/* 320 */
@media screen and (max-width:330px) {

    p.info-text {
        font-size: 14px;
    }
}