.recent-work {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 60px 0;
}

.mokeup-designs {
  margin: 0 0 60px 0;
}

h6.section-title {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e8e8ea;
  margin: 0 0 40px 0;
  padding: 0 0 15px 0;
  color: #1f271b;
}

.recent-work h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 35px;
  line-height: 47px;
  font-weight: 500;
  margin: 0;
  color: #1f271b;
}

.mokeup-design-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 0 0;
}

a.border-black-btn.get-in-touch {
  font-size: 14px;
  padding: 10px;
  font-weight: 400;
}

.mokeup-design-info h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #1f271b;
}
.mokeup-design-info a {
  text-decoration: none;
}

.mokeup-designs a:hover {
  text-decoration: underline;
  transition: all 0.5s;
}

.mokeup-designs a {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  transition: all 0.5s;
  margin: 0 0 5px 0;
  display: inline-block;
}

.mokeup-designs img {
  box-shadow: 0 0 20px 0px #0000001c;
}

.mokeup-designs i img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

/* 992 */
@media screen and (max-width: 1199px) {
  a.border-black-btn {
    font-size: 16px;
    padding: 12px 26px;
  }
  .recent-work h3 {
    font-size: 31px;
}
}
/* 768 */
@media screen and (max-width:991px) {
    .recent-work h3 {
        font-size: 28px;
    }
    a.border-black-btn.get-in-touch {
        font-size: 13px;
        padding: 7px 10px;
    }
    section.our-work {
        padding: 60px 0 0 0;
    }
}
/* 576 */
@media screen and (max-width:767px) {
    .recent-work h3 {
        font-size: 25px;
    }
    a.border-black-btn {
        font-size: 15px;
        padding: 10px 23px;
    }
    .recent-work h3 {
        line-height: 37px;
    }
    .recent-work {
        margin: 0 0 30px 0 !important;
    }
}
/* 480 */
@media screen and (max-width:575px) {

    section.our-work {
        padding: 50px 0 0 0;
    }
    .recent-work h3 {
        font-size: 22px;
    }
}
/* 375 */
@media screen and (max-width:479px) {
    .recent-work {
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    a.border-black-btn {
        font-size: 14px;
        padding: 8px 18px;
    }
}
/* 320 */
@media screen and (max-width:330px) {

    section.our-work {
        padding: 20px 0 0 0;
    }
    .mokeup-design-info {
        align-items: start;
        flex-direction: column;
        gap: 15px;
    }
    .recent-work h3 {
        font-size: 20px;
        line-height: 30px;
    }
}