.home-banner {
    margin: 90px 0;
    padding: 100px 0;
    position: relative;
    background: #f3f7fd;
    margin-bottom: 100px;
}
.section-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 62px;
    font-weight: 600;
    color: #1f271b;
    margin: 8px 0 16px;
}
p.section-info {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
    margin: 25px 0 35px;
    color: #1f271b;
}
/* 1200 */
@media screen and (max-width:1429px) {
    section.home-banner {
        padding: 60px 0 100px;
    }

    h2.section-title {
        font-size: 48px;
        max-width: 800px;
    }
}
/* 992 */
@media screen and (max-width:1199px) {
    section.home-banner {
        padding: 30px 0 100px;
    }
    h2.section-title {
        font-size: 43px;
        margin: 0;
    }
    p.section-info {
        margin: 25px 0;
    }
}
/* 768 */
@media screen and (max-width:991px) {
    
    p.section-info {
        margin: 30px 0;
        font-size: 15px;
        line-height: 34px;
    }
    h2.section-title {
        font-size: 40px;
    }
}
/* 576 */
@media screen and (max-width:767px) {
    h2.section-title {
        font-size: 32px;
    }
    p.section-info {
        margin: 20px 0;
        line-height: 28px;
    }
}
/* 480 */
@media screen and (max-width:575px) {

    h2.section-title {
        font-size: 27px;
    }
    section.home-banner {
        padding: 30px 0 50px;
    }
}
/* 320 */
@media screen and (max-width:330px) {
    h2.section-title {
        font-size: 22px;
    }
    p.section-info {
        margin: 10px 0 20px;
        line-height: 27px;
        font-size: 14px;
    }
}